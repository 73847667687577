import axios from 'axios';

const URL_API_PERSONA = process.env.VUE_APP_URL_API_PERSONA;
const URL_API_PERSONA_PERSONA_OP = process.env.VUE_APP_URL_API_PERSONA_PERSONA_OP;

export class PersonaApiService {
  /**
   * Devuelve todas las personas
   * @return Persona[]
   */
  getAllPersonas() {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_PERSONA}getAllPersonas`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Devuelve las personas asociadas a una opid
   * @param {*} opuid
   * @return Persona[]
   */
  getPersonasByOpUid(opuid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    if (opuid) {
      const url = `${URL_API_PERSONA}getPersonasByOpUid/${opuid}`;
      return axios
        .get(url,config)
        .then(function(response) {
          return response.data;
        })
        .catch(function(error) {
          if (error.response && (error.response.status===401 || error.response.status===403)){
            window.location.href = process.env.BASE_URL + '#/logout'
          }
          throw error;
        });
    }
  }

  /**
   * Devuelve las personas que esten en la bd y no estén asociadas a la opid
   * @param {*} opid
   * @return Persona[]
   */
  getPersonasNotInOpUid(opuid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_PERSONA}getPersonasNotInOpUid/${opuid}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Devuelve una persona dada su 'personaid'
   * @param {*} personaid
   * @return Persona[]
   */
  getPersonaById(personaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_PERSONA}getPersonaById/${personaid}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  /**
   * Devuelve la personas segun los ids de usario asociado al supportBoard
   * @param {*} agentId 
   * @returns 
   */
  getPersonasByChatIds(agentIds) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_PERSONA}findAllPersonaByChatId`;
    return axios
      .post(url, agentIds, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Devuelve las personas cuyo nombre contenga 'nombre'
   * @param {*} nombre
   * @return Persona[]
   */
  getPersonasNombreLike(nombre) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_PERSONA}getPersonasNombreLike/${nombre}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Inserta una Persona
   * @param {*} Persona
   * @return response respuesta de la insercion
   */
  createPersona(persona) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_PERSONA}createPersona`;
    return axios
      .post(url, persona, config)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Actualiza una persona
   * @param {*} persona
   * @return response respuesta de la actualizacion
   */
  updatePersona(persona) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    // const url = `${URL_API_PERSONA}updatePersona/${persona.mspersonaid}`;
    const url = `${URL_API_PERSONA}updatePersona`;
    return axios
      .put(url, persona, config)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Actualiza la foto de una persona
   * @param {*} persona
   * @param {*} file
   * @return response respuesta de la actualizacion
   */
  updatePersonaFoto(persona, usuModif) {
    const url = `${URL_API_PERSONA}updatePersonaFoto/${persona.mspersonaid}/${usuModif}`;
    let formData = new FormData();
    formData.append('file', persona.imagen);
    var options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")
      }
    };
    return axios
      .put(url, formData, options)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Borra la foto de una persona
   * @param {*} persona
   * @return response respuesta de la actualizacion
   */
  deletePersonaFoto(persona) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    var userid = localStorage.getItem('user') ? localStorage.getItem('user') : null;
    const url = `${URL_API_PERSONA}deletePersonaFoto/${persona.mspersonaid}/${userid}`;
    return axios
      .put(url, persona, config)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Crea un registro en la tabla mspersonaop
   * @param {*} mspersonaoppersonaid
   * @param {*} mspersonaopopuid
   * @param {*} mspersonaoprol
   * @returns resultado
   */
  createPersonaOp(mspersonaoppersonaid, mspersonaoppersonauid, mspersonaopopuid, mspersonaoprol) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_PERSONA_PERSONA_OP}createPersonaop`;
    var data = {
      persona: {
        mspersonaid: mspersonaoppersonaid,
        mspersonauid: mspersonaoppersonauid
      },
      op: {
        msopuid: mspersonaopopuid
      },
      mspersonaoprol: mspersonaoprol,
      mspersonaoprolstr: 'Vendedor'
    };
    return axios
      .post(url, data, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  /**
   * Borra un registro de la tabla mspersonaop
   * @param {*} mspersonaoppersonaid
   * @param {*} mspersonaopopid
   */
  // deletePersonaOp(mspersonaoppersonaid, msopuid) {
  //   const url = `${URL_API_PERSONA_PERSONA_OP}deletePersonaop/${mspersonaoppersonaid}/${msopuid}`;
  //   return axios
  //     .delete(url)
  //     .then(function(response) {
  //       return response.data;
  //     })
  //     .catch(function(error) {
  //       throw error;
  //     });
  // }

  /**
   * Borra un registro de la tabla mspersonaop
   * @param {*} mspersonaoppersonaid
   * @param {*} mspersonaoppersonauidAux
   * @param {*} mspersonaopopuidAux
   * @param {*} mspersonaoprol
   */

   /*
   TODO:
    msopid pasar parametro 
   */
  deletePersonaOp (mspersonaoppersonaid, mspersonaoppersonauidAux, mspersonaopopuidAux, mspersonaoprol) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion"), useCredentails: true}
    };
    const url = `${URL_API_PERSONA_PERSONA_OP}deletePersonaop`;
    var data = {
      persona: {
        mspersonaid: mspersonaoppersonaid,
      },
      op: {
        msopid: 1
      },
      mspersonaoppersonauid: mspersonaoppersonauidAux,
      mspersonaopopuid: mspersonaopopuidAux,
      mspersonaoprol: mspersonaoprol,
      mspersonaoprolstr: 'Vendedor'
    }
    return axios
      .post(url, data, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  findPersona(persona) {
    let config = {
      headers: {
                'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")
                }
    };
    const url = `${URL_API_PERSONA}find`;
    return axios
      .post(url, persona, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        // if (error.response && (error.response.status===401 || error.response.status===403)){
        //   window.location.href = process.env.BASE_URL + '#/logout'
        // }
        throw error;
      });
  }
}
