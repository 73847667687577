<template>
 <v-col class="col-12 pa-0">
   <input class="d-none" text ref="textEan" v-model="eanOcultoScan" @keyup.enter="escaneo()"/>
   <StreamBarcodeReader
      @decode="(a, b, c) => onDecode(a, b, c)"
      @error="() => onError()"
      @loaded="() => onLoaded()"
    ></StreamBarcodeReader>

      <!-- <h5>{{ text || "Código no válido" }}</h5> -->
    
 </v-col>

  <!--
  
    <v-btn class="icon ticket">
    </v-btn>
</v-card>-->
</template>


<script lang="ts">
  import Vue from 'vue'
  import { StreamBarcodeReader } from "vue-barcode-reader";
  
  export default Vue.extend({
    name: 'Scan',
    components: {
      StreamBarcodeReader,
    },
    data () {
      return {
        text: "",
        id: null,
        eanOcultoScan: '',
      }
    },
    props: {
      msg: String,
    },
    mounted() {
      // this.$refs.textEan.focus()
    },
    methods: {
      onDecode(a, b, c) {
        console.log(a, b, c);
        this.text = a;
        this.$emit('search-by-ean-code', a)
        if (this.id) clearTimeout(this.id);
        this.id = setTimeout(() => {
          if (this.text === a) {
            this.text = ''
          }
        }, 5000);
      },
      onLoaded() {
        // console.log("load");
      },
      onError(){
        alert('Error')
      },
      escaneo() {
        this.$emit('search-by-ean-code', this.eanOcultoScan)
        // this.eanOcultoScan = ''
        // this.$refs.textEan.focus()
      }
    }
  })
</script>