import axios from 'axios'
const URL_API_FICHAJE = process.env.VUE_APP_URL_API_FICHAJE

export class FichajeApiService {
  /**
   * Devuelve todos los paises
   * @return Pais[]
   */
  getAllFichaje () {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_FICHAJE + 'getAllFichaje'
    return axios
      .get(url,config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getFichajeByAnhoMesAndPersonaId (anho, mes, msfichajepersonaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_FICHAJE + 'getFichajeByAnhoMesAndPersonaId/' + anho + '/' + mes + '/' + msfichajepersonaid
    return axios
      .get(url,config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getFichajeByAnhoMesAndPersonaUid (anho, mes, msfichajepersonauid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_FICHAJE + 'getFichajeByAnhoMesAndPersonaUid/' + anho + '/' + mes + '/' + msfichajepersonauid
    return axios
      .get(url,config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getDistinctAnhosFichajeByPersonaId (msfichajepersonaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_FICHAJE + 'getDistinctAnhosFichajeByPersonaId/' + msfichajepersonaid
    return axios
      .get(url,config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getDistinctMesesFichajeByAnhoAndPersonaId (anho, msfichajepersonaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_FICHAJE + 'getDistinctMesesFichajeByAnhoAndPersonaId/' + anho + '/' + msfichajepersonaid
    return axios
      .get(url,config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getFichajeByFechasAndPersonaId (fechaInicial, fechaFinal, msfichajepersonaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_FICHAJE + 'getFichajeByFechasAndPersonaId/' + fechaInicial + '/' + fechaFinal + '/' + msfichajepersonaid
    return axios
      .get(url,config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  findAllFichajeFilter (filterFichajeList) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_FICHAJE}findAllfilter`
    return axios
      .post(url, filterFichajeList, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  findFichajeFilter (filterFichajeList) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_FICHAJE}findfilter`
    return axios
      .post(url, filterFichajeList, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response && (error.response.status===401 || error.response.status===403)){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  createFichaje(fichaje) {
    const url = `${URL_API_FICHAJE}createFichaje`;
    let config = {
      headers: { Authorization: localStorage.getItem("token"),  'instalacion' : localStorage.getItem("instalacion") }
    };
    return axios
      .post(url, fichaje, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + "#/logout";
        }
        throw error;
      });
  }
}
