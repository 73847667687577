<template>
  <v-snackbar
    v-model="error1"
    :color="color"
    multi-line
    :timeout="timeout"
    >
    {{ errors[0] }}
    <v-btn fab text
      @click="error1 = false"
      >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      timeout:6000,
      error1: false
    }
  },
  props: {
    error: { type: Boolean, required: true },
    errors: { type: Array, required: true },
    color: { type: String, required: true }
  },
  watch: {
    error: function(n) {
      this.error1 = n 
    },
    error1: function(n) {
      if (!n) {
        this.$emit('closeError')
      }
    }
  },
}
</script>
